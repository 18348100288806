<template>
    <IntervalsBase :intervals="intervals" :mode='mode' :baseOct="baseOct" />
</template>

<script>
    import IntervalsBase from './IntervalsBase.vue'

    export default {
        name: 'IntervalsBasic',
        props: {},
        components:{
            IntervalsBase
        },
        data() {
            return {

                baseOct: 4,

                mode: null,

                intervals: [
                    {
                        'title': 'Прима',
                        'index': 0
                    },
                    {
                        'title': 'Малая Секунда',
                        'index': 1
                    },
                    {
                        'title': 'Большая Секунда',
                        'index': 2
                    },
                    {
                        'title': 'Малая Терция',
                        'index': 3
                    },
                    {
                        'title': 'Большая Терция',
                        'index': 4
                    },
                    {
                        'title': 'Чистая кварта',
                        'index': 5
                    },
                    {
                        'title': 'Тритон',
                        'index': 6
                    },
                    {
                        'title': 'Чистая Квинта',
                        'index': 7
                    },
                    {
                        'title': 'Малая Секста',
                        'index': 8
                    },
                    {
                        'title': 'Большая Секста',
                        'index': 9
                    },
                    {
                        'title': 'Малая Септима',
                        'index': 10
                    },
                    {
                        'title': 'Большая Септима',
                        'index': 11
                    },
                    {
                        'title': 'Октава',
                        'index': 12
                    },
                    {
                        'title': 'Малая Нона',
                        'index': 13,
                    },
                    {
                        'title': 'Большая Нона',
                        'index': 14,
                    },
                    {
                        'title': 'Малая Децима',
                        'index': 15,
                    },
                    {
                        'title': 'Большая Децима',
                        'index': 16,
                    },
                    {
                        'title': 'Чистая Ундецима',
                        'index': 17,
                    },
                    {
                        'title': 'Увеличенная Ундецима',
                        'index': 18,
                    },
                    {
                        'title': 'Чистая Дуодецима',
                        'index': 19,
                    },
                    {
                        'title': 'Малая Тердецима',
                        'index': 20,
                    },
                     {
                        'title': 'Большая Тердецима',
                        'index': 21,
                    },
                    {
                        'title': 'Малая Квартдецима',
                        'index': 22,
                    },
                    {
                        'title': 'Большая Квартдецима',
                        'index': 23,
                    },
                    {
                        'title': 'Чистая Квинтдецима',
                        'index': 24,
                    },
                ]
            }
        }

      
    }
</script>

