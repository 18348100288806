<template>
    <div class="main-area">
        <div class="controls">
            <div class="left-side">
                <div class="left-header"></div>
                <div class="left-center">
                    <div class="circle" v-on:click="play">
                        <h2 class="circle-title"> Кликни чтобы проиграть интервал </h2>
                    </div>
                </div>
                <div class="left-footer">

                    <button class="skip-button" v-on:click="skip()">
                            Пропустить ответ, проиграть слейдущий интервал
                    </button>
                </div>
            </div>
            <div class="right-side">
                <div class="right-header">
                    <p class="bold good-text">Правильных ответов {{goals}}</p>
                    <p class="bold bad-text">Не правильных ответов {{errors}}</p>
                </div>
                <div class="right-center buttons">
                    <button v-bind:class="{ active: isActive }" v-for="interval in intervals" :key="interval.index" v-on:click="checkAnswer(interval)">
                        {{interval.title}}
                    </button>
                </div>
                <div class="right-footer">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as Tone from 'tone'

    export default {
        name: 'IntervalsBase',
        props: {
            intervals: Array,
            baseOct: Number,
            mode: String

        },
        data() {
            return {

                notesSheet: [
                    'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'
                ],

                notes: [],

                interval: 0,

                errors: 0,

                goals: 0,
            }
        },

        mounted() {
            this.generate();
            this.play();
        },

        methods: {
            generate(firstToneIndex = null){            

                if (firstToneIndex == null){
                    firstToneIndex = this.getRandomInt(this.notesSheet.length);
                }

                if (this.mode == null){
                    this.mode = Math.random() > 0.5 ? 'DESC' : 'ASC'
                }

                let firstToneOct = this.baseOct
                let firstToneGlobalIndex = this.notesSheet.length * (firstToneOct--) + firstToneIndex

                this.interval = this.getRandomInt(this.intervals.length);

                let secondToneGlobalIndex = firstToneGlobalIndex + this.interval

                if ( this.mode == 'DESC' ){
                    secondToneGlobalIndex = firstToneGlobalIndex - this.interval
                }


                let secondToneOct = Math.floor(secondToneGlobalIndex / this.notesSheet.length)


                let secondToneIndex = secondToneGlobalIndex - (this.notesSheet.length * (secondToneOct--))

                this.tones = [
                    {
                        oct: firstToneOct,
                        note: this.notesSheet[firstToneIndex]
                    },
                    {
                        oct: secondToneOct,
                        note: this.notesSheet[secondToneIndex]
                    }
                ];
            },


            findAnotherToneByInterval(toneIndex,interval){

                let position = toneIndex + interval
                
                if (position <= this.notesSheet) {
                    return position
                }

            },

            play() {
                const synth = new Tone.Synth().toDestination();
                const now = Tone.now();

                this.tones.forEach(function (tone, index) {
                    synth.triggerAttackRelease(tone.note + tone.oct, "9n", now + index / 2);
                });
            },

            checkAnswer(answer) {
                if (answer.index === this.interval) {
                    alert("Верно!")
                    this.generate()
                    this.play()
                    this.goals++
                } else {
                    this.errors++
                    alert("Не Верно!")
                }
            },
            getRandomInt(max) {
                return Math.floor(Math.random() * Math.floor(max))
            },

            skip(){
                this.errors++
                this.generate()
                this.play()
            }


        }
    }
</script>

<style>

    .circle {
        background-color: cadetblue;
        align-self: center;
        height: 300px;
        width: 300px;
        border-radius: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: white;
    }

    .main-area {
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
    }


    .buttons {
        margin-top: 30px;
        display: flex;
        vertical-align: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
    }

    .controls{
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .buttons > button {
        background-color: cadetblue; 
        border: none;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 10px;
    }

    .left-side{
        width: 50%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        background-color: #eee;
        height: 100%;
    }

    .circle-title{
        text-align: center;
    }

    .right-side{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .good-text{
        color: green
    }

    .bad-text{
        color: red
    }

    .bold{
        font-weight: bold;
    }

    .skip-button{
        width: 100%;
        height: 50px;
        background-color: teal;
        color: white;
    }

    .left-footer{
        width: 100%;
    }


    @media (max-width: 769px) {
    .left-side, .right-side {
        width: 100%;
        height: 50%;
    }

    .controls {
        flex-wrap: wrap;
    }

    .right-side{
        overflow-x: auto;
    }
}


</style>
