<template>
    <div class="main-menu">
        <nav class="menu-items">
            <router-link to="/intervals_basic">Базовые Интервалы</router-link>
            <router-link to="/intervals_extended">Расширенные Интервалы</router-link>
            <router-link to="/what_is_note">Угадай ноту</router-link>
            <router-link to="/theory">Теория</router-link>
        </nav>
    </div>
</template>

<script>
 export default {
        name: 'Main',
        props: {},
        data() {
            return {

            }
        }
}
</script>
<style>

.main-menu{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #d6d6d6
}

.menu-items{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
}

.menu-items a{
    color: cadetblue;
    text-decoration: none;
    list-style: none;
    font-size: 30px;
    background-image: linear-gradient(cadetblue,cadetblue);
    background-position: 0 100%; /*OR bottom left*/
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
        background-size 0.3s,
        background-position 0s 0.3s; /*change after the size immediately*/
}

.menu-items a:hover{
    background-position: 100% 100%; /*OR bottom right*/
    background-size: 100% 2px;
}



</style>