import MainMenu from './components/MainMenu.vue'
import IntervalsBasic from './components/IntervalsBasic.vue'
import IntervalsExtended from './components/IntervalsExtended.vue'
import InProgress from './components/InProgress.vue'

export default [
    {
        path: '/',
        component: MainMenu
    }
    ,
    { 
        path: '/intervals_basic',
        component: IntervalsBasic
    },
    { 
        path: '/intervals_extended',
        component: IntervalsExtended
    },
    { 
        path: '/what_is_note',
        component: InProgress
    },
    { 
        path: '/theory',
        component: InProgress
    },
]